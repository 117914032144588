.shadow-one{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
}

@import "theme";
@import "~bootstrap/scss/bootstrap";

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        color: $gray-800;
        border: 0px;
        .arrow{
            color: map-get($theme-colors, $color)
        }
        &:hover{
            .arrow{
                color: white;
            }
        }
    }
}

body{
    color: $gray-800;
}

@import "fonts";
.clickable{
    cursor: pointer;
}

.question, .confirmation{
    @media (max-width: 768px){
        font-size: 14px;
    }
}